<template>
  <div class="q-mb-lg" :class="{ 'text-center': center }">
    <p
      v-if="$slots.main || mainTitle"
      class="text-bold"
      :class="mainTitleColor ? mainTitleColor : 'text-primary'"
      style="font-size: 40px"
    >
      <slot name="main">
        {{ mainTitle }}
      </slot>
    </p>
    <div class="row q-col-gutter-x-md" :class="titleClasses">
      <div v-if="!!back" class="col-auto">
        <q-btn
          :to="typeof back === 'string' ? back : undefined"
          icon="fa-solid fa-arrow-left"
          round
          outline
          size="sm"
          color="muted"
          data-test="title-back"
          @click="typeof back === 'function' && back()"
        />
      </div>
      <div class="col">
        <h1
          class="q-my-none"
          :class="{
            big: bigTitle,
          }"
          data-test="title"
        >
          <slot>
            <div class="flex" :class="{ 'justify-center': center }">
              <q-icon v-if="icon" :name="icon" left />
              <span v-if="title">{{ title }}</span>
            </div>
          </slot>
        </h1>
      </div>
    </div>
    <p
      v-if="subtitle || $slots.subtitle"
      class="text-muted text-body1"
      data-test="subtitle"
    >
      <slot name="subtitle">
        {{ subtitle }}
      </slot>
    </p>
    <cta-error />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useQuasar } from 'quasar'

import CtaError from './CtaError.vue'

const $q = useQuasar()

// PROPS
const props = defineProps<{
  mainTitle?: string
  mainTitleColor?: string
  title?: string
  bigTitle?: boolean
  subtitle?: string
  icon?: string
  back?: string | (() => void)
  center?: boolean
}>()

const titleCenter = ref<null | string>('')

onMounted(() => {
  titleCenter.value = $q.screen.gt.sm ? '' : 'text-center'
  watchEffect(() => {
    titleCenter.value = $q.screen.gt.sm ? '' : 'text-center'
  })
})

const titleClasses = computed(() => {
  let classes = titleCenter.value
  if (props.subtitle) {
    classes += ' q-mb-sm'
  }
  if (props.mainTitle) {
    classes += ' q-mt-md'
  } else {
    classes += ' q-mt-none'
  }
  return classes
})
</script>
