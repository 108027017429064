<template>
  <!-- CTA -->
  <call-to-action-render
    :cta-component
    :error-type="errorType"
    class="q-my-md"
  />
  <!-- Error -->
  <form-general-error :error-type="errorType" class="q-my-md" />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, markRaw } from 'vue'

import CallToActionRender from 'components/common/ui/pageElements/CallToActionRender.vue'

import FormGeneralError from 'components/common/ui/form/FormGeneralError.vue'

// PROPS
withDefaults(
  defineProps<{
    errorType?: string
  }>(),
  {
    errorType: 'generalError',
  }
)

const ctaComponent = computed(() => {
  return process.env.WT_ENV === 'receiver'
    ? markRaw(
        defineAsyncComponent(
          () => import('components/common/ui/cta/ShopCallToAction.vue')
        )
      )
    : markRaw(
        defineAsyncComponent(
          () => import('components/sender/ui/cta/CallToAction.vue')
        )
      )
})
</script>
