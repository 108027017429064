<template>
  <q-slide-transition @show="isShown = true" @hide="isShown = false">
    <component
      :is="ctaComponent"
      v-if="show && errorStore.callToAction[errorType]"
      :cta="errorStore.callToAction[errorType]"
    />
  </q-slide-transition>
</template>

<script setup lang="ts">
import { ref, watch, watchEffect } from 'vue'

import { useErrorStore } from 'stores/common/error'

const errorStore = useErrorStore()

// PROPS
const props = withDefaults(
  defineProps<{
    ctaComponent: object
    errorType?: string
    animate?: boolean
  }>(),
  { errorType: 'generalError' }
)

const show = ref(false)
watchEffect(() => {
  if (errorStore.callToAction[props.errorType]) {
    // Allow to avoid rendering the CTA when it come from a call that's made from prefetch
    setTimeout(() => {
      show.value = true
    }, 0)
  } else {
    show.value = false
  }
})

const isShown = ref(false)
const animationClass = ref<string | null>(null)

watch(
  () => props.animate,
  () => {
    if (isShown.value) {
      // Add animation class
      animationClass.value = 'errorAnime'
      // Remove it after animation
      setTimeout(() => {
        animationClass.value = null
      }, 360)
    }
  }
)

defineExpose({
  isShown,
})
</script>

<style lang="scss" scoped>
.errorAnime {
  animation: q-field-label 0.36s;
}
</style>
